import React, { useContext } from 'react'
import { GlobalStateContext } from '../components/context/globalContextProvider'
import Layout from '../components/layout/layout'
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import SEO from '../components/seo'

// Stockist page component for contact information
const StockistPage = () => {

    // Extracting the global state into a local variable
    const state = useContext(GlobalStateContext)

    // Rep information
    const reps = {
        europe: {
            name: "Ewan Mason",
            email: 'ewan@phresh.eu',
            twitter: '',
            instagram: ''
        }
    }

    // Selecting the correct rep depending on the location
    const currentRep = state.location === 'Europe' ? reps.europe : reps.ROW

    return (
        // Wrap contents in layout component
        <Layout>
            <SEO title="Stockist" />
            {/* Page introduction */}
            <section className="stockist-page">
                <div className="stockist-page-inner">
                    <div className="text-area" style={{ marginTop: 0 }}>
                        <h1>Become a Stockist</h1>
                        <h3>Get in touch with your local Phresh representative to become a stockist.</h3>
                    </div>

                    {/* Representative information */}
                    <div>
                        <div className="card-right">
                            {/* Dynamic details depending on location */}
                            <h3>{/* {state.location === 'Europe' ? 'European' : 'Global'} */}European Representative</h3>
                            <h1>{/* {currentRep.name} */}Ewan Mason</h1>
                            <ul className="social-links">
                                <li><a href={`mailto:ewan@phresh.eu`} style={{ display: 'flex', alignItems: 'center' }}>{currentRep.email} <ArrowRightAlt style={{ marginLeft: '12px', justifyContent: 'center' }} /></a></li>
                                <li><a href="https://www.youtube.com/channel/UCDTHOE83JjY3xg1tBbOPLsQ" target="_blank" rel="noreferrer noopener" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Youtube <ArrowRightAlt style={{ marginLeft: '12px' }} /></a></li>
                                <li><a href="https://www.instagram.com/phresh_uk/" rel="noreferrer noopener" target="_blank" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Instagram <ArrowRightAlt style={{ marginLeft: '12px' }} /></a></li>
                            </ul>
                        </div>
                    </div>
                    {/* Page title in background */}
                    <h1 className="transparent-title">Contact</h1>
                </div>
            </section>
        </Layout >
    )
}

export default StockistPage